@import "assets/styles/colors.scss";

.footer {
	position: fixed;
	top: 15px;
	right: 12px;
	font-size: 16px;
	z-index: 10;
	background-color: transparentize($light-alternative, 0.4);
	border: 1px solid darken($light-alternative, 5);
	padding: 5px 12px;
	border-radius: 14px;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;

	& > a {
		color: $dark;
		text-decoration: none;
		transition: 0.3s;

		&:hover {
			color: $accent;
		}

		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	&.active {
		opacity: 1;
		pointer-events: inherit;
	}
}
