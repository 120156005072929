@import "assets/styles/colors.scss";

.banner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	.logo {
		margin-bottom: 20px;
	}

	.slogan {
		color: $accent;
		margin-bottom: 50px;
		font-weight: normal;
		font-size: 23px;
	}

	.description {
		font-size: 150%;
		max-width: 650px;
		font-weight: normal;
		text-align: center;
	}

	@media screen and (max-width: 900px) {
		& {
			padding: 80px 20px;
		}
	}
}
