@import "assets/styles/colors.scss";

.more {
	font-size: 10px;
	padding: 10px;
	height: 40px;
	color: $dark;
	border: 2px solid transparentize($dark, 0.2);
	border-radius: 22px;

	& > svg {
		animation: effect 1.8s infinite;
	}
}

@keyframes effect {
	0% {
		opacity: 0;
		transform: translateY(0);
	}
	15% {
		opacity: 0.8;
		transform: translateY(0);
	}
	60% {
		opacity: 0.8;
		transform: translateY(30px);
	}
	70% {
		opacity: 0;
		transform: translateY(30px);
	}
	100% {
		opacity: 0;
		transform: translateY(0);
	}
}
