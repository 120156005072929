@import "assets/styles/colors.scss";

.contact {
	display: flex;
	flex-direction: column;
	align-items: center;

	> h2 {
		color: $accent;
		font-weight: normal;
		font-size: 50px;
		margin-bottom: 40px;
	}

	.references {
		.intro {
			font-size: 18px;
			margin-bottom: 35px;
			max-width: 800px;
			text-align: center;
		}

		.companies {
			margin-bottom: 35px;
			display: flex;
			justify-content: center;
			align-items: center;

			> a {
				transition: 0.3s;
				margin-right: 40px;

				img {
					max-width: 120px;
				}

				&:last-child {
					margin-right: 0;

					img {
						max-width: 100px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		& {
			padding: 0 20px 20px 20px;
		}

		& > h2 {
			font-size: 40px;
		}

		& > h3 {
			padding: 0 10px;
			text-align: center;
		}

		.holder {
			grid-template-columns: 1fr;
		}
	}
}
