.app {
	width: 100%;
	display: flex;
	opacity: 0.01;
	transition: 0.4s;

	&.active {
		opacity: 1;
	}
}
