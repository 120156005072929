@import "assets/styles/colors.scss";

.copy {
	border-top: 1px solid darken($light-alternative, 4);
	border-right: 1px solid $light-alternative;
	background-color: $light;
	color: $dark;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	padding: 8px 10px;
	font-size: 12px;
	box-shadow: 0 -10px 10px -10px rgba(0,0,0,0.15);
	z-index: 10;

	a {
		color: $accent;
	}
}
