@import "assets/styles/colors.scss";

.packages {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > h2 {
		color: $accent;
		font-weight: normal;
		font-size: 50px;
		margin-bottom: 40px;
	}

	& > h3 {
		font-weight: normal;
		font-size: 120%;
		margin-bottom: 50px;
	}

	.holder {
		display: grid;
		width: 100%;
		max-width: 900px;
		grid-template-columns: 1fr 1fr 1fr;

		.item {
			padding: 0 2px 30px 2px;
			min-width: 0;

			.title {
				padding: 10px 10px 0 10px;
				color: $accent;
				font-size: 120%;
			}

			.data {
				height: 35px;
				overflow: visible;

				.content {
					background-color: $light;
					padding: 0 10px 10px 10px;
					margin-top: 8px;
					font-size: 90%;
					overflow: hidden;
					transition: 0.3s;
					max-height: 25px;
					position: relative;

					.description {
						min-height: 25px;
					}

					.tech {
						padding: 8px 0 1px;

						> a {
							display: inline-block;
							color: $accent;
							margin-right: 8px;
							font-size: 11px;
							text-transform: uppercase;
							cursor: pointer;
							text-decoration: none;
							white-space: nowrap;
							transition: 0.3s;

							&:not(:last-child) {
								margin-right: 15px;
							}

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}


			&:hover, &:active {
				.content {
					z-index: 30;
					box-shadow: rgba(0, 0, 0, 0.15) 0 3px 3px 0;
					max-height: 120px;
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		& {
			padding: 10px 20px;
		}

		& > h2 {
			font-size: 40px;
		}

		& > h3 {
			padding: 0 10px;
			text-align: center;
		}

		.holder {
			grid-template-columns: 1fr;
		}
	}
}
