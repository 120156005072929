@import "assets/styles/colors.scss";

.services {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > h2 {
		color: $accent;
		font-weight: normal;
		font-size: 50px;
		margin-bottom: 40px;
	}

	.panel {
		display: flex;
		width: 90vw;
		max-width: 1000px;

		.service {
			background-color: transparentize(lighten($light-alternative, 3), 0.4);
			padding: 20px 18px;
			flex-grow: 1;
			flex-basis: 0;

			&:not(:last-child) {
				margin-right: 10px;
			}

			.title {
				color: $accent;
				margin-bottom: 20px;
				font-size: 160%;
			}
		}
	}

	@media screen and (max-width: 1300px) {
		.panel {
			flex-direction: column;
			max-width: 800px;
		}
	}

	@media screen and (max-width: 1150px) {
		.panel {
			flex-direction: column;
			max-width: 600px;
		}
	}

	@media screen and (max-width: 900px) {
		& > h2 {
			font-size: 40px;
		}

		.panel {
			flex-direction: column;
			max-width: 500px;
		}
	}
}
