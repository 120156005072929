@import "assets/styles/colors.scss";

.cta {
	.content {
		text-align: center;
		margin-bottom: 14px;
	}

	.btn {
		background-color: $accent;
		color: $light;
		text-decoration: none;
		padding: 10px 25px;
		border-radius: 20px;
		font-size: 110%;
		display: block;
		transition: 0.3s;
		text-align: center;

		&:hover {
			transform: scale(1.1, 1.1);
			background-color: lighten($accent, 5);
		}
	}
}
