.construction {
	font-size: 150%;
	color: #767676;
	text-align: center;

	.indicator {
		height: 8px;
		width: 300px;
		margin-top: 30px;
		border-radius: 4px;
		background-color: #EEE;
		border: 1px solid #DDD;

		&::before {
			content: '';
			display: block;
			height: 8px;
			border-radius: 4px;
			background-color: #A12935;
			width: 100px;
		}
	}
}
