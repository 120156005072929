@import "assets/styles/colors.scss";

.section {
	display: flex;
	height: 100vh;
	width: 100%;
	position: relative;
	flex-shrink: 0;
	background-color: $light;
	overflow: hidden;
	border-right: 1px solid darken($light-alternative, 6);
	box-sizing: border-box;

	&:not(:first-child) {
		border-top: 1px solid darken($light-alternative, 6);
		box-shadow: 0 8px 15px 0 transparentize(darken($light-alternative, 15), 0.65) inset;
	}

	.background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
	}

	.content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.more {
		position: absolute;
		right: 20px;
		bottom: 60px;
		z-index: 3;
		opacity: 0.6;
	}

	.tip-holder {
		position: absolute;
		right: 0;
		bottom: 15px;
		left: 0;
		z-index: 3;
		display: flex;
		justify-content: center;
	}

	@media screen and (max-width: 1200px) {
		& {
			height: auto;
			padding: 70px 0;

			.content {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
			}
		}
	}
}
