@import "assets/styles/colors.scss";

.policy {
	border: 1px solid $accent;
	border-radius: 25px;
	background-color: $light;
	color: $dark;
	position: fixed;
	top: 20px;
	left: 20px;
	padding: 10px 14px;
	font-size: 12px;
	box-shadow: 0 10px 10px -10px rgba(0,0,0,0.15);
	z-index: 20;
	display: flex;
	align-items: center;

	a {
		color: $accent;
	}

	> svg {
		color: $accent;
		font-size: 30px;
		margin-right: 10px;
	}

	> div {
		max-width: 300px;
	}

	> button {
		background-color: $accent;
		border: 1px solid darken($accent, 5);
		margin-left: 14px;
		color: $light;
		font-size: 11px;
		cursor: pointer;
		padding: 5px 10px 6px 10px;
		border-radius: 16px;
		transition: 0.3s;

		&:hover {
			background-color: darken($accent, 5);
		}
	}
}
