@import "assets/styles/colors.scss";

.cases {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > h2 {
		color: $accent;
		font-weight: normal;
		font-size: 50px;
		margin-bottom: 40px;
	}

	.panel {
		display: flex;
		width: 90vw;
		max-width: 900px;

		.case {
			background-color: transparentize(lighten($light-alternative, 3), 0.4);
			padding: 20px 22px;
			flex-grow: 1;
			flex-basis: 0;
			display: flex;
			flex-direction: column;

			&:not(:last-child) {
				margin-right: 10px;
			}

			.title {
				color: $accent;
				margin-bottom: 20px;
				font-size: 140%;
				min-height: 50px;
			}

			.spotlight {
				padding: 20px;
				margin-bottom: 15px;
				text-align: center;

				> img {
					max-height: 180px;
				}
			}

			> button {
				background-color: $light-alternative;
				border: 1px solid darken($light-alternative, 10);
				color: lighten($dark, 40);
				display: none;
				width: 40px;
				height: 40px;
				justify-content: center;
				align-items: center;
				margin-left: auto;
				border-radius: 50%;
				transition: 0.3s;
				cursor: pointer;

				&:hover {
					transform: scale(1.1, 1.1);
					color: lighten($dark, 10);
				}
			}
		}
	}

	.details {
		pointer-events: none;
		opacity: 0;
		transition: 0.3s;
		position: fixed;
		z-index: 50;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: transparentize($dark, 0.08);
		overflow: hidden;

		.frame {
			transition: 0.3s ease-out;
			width: 90vw;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			transform: translateX(90vw);

			.title {
				color: $light;
				background-color: $accent;
				padding: 10px 18px;
				font-size: 120%;

				display: flex;
				align-items: center;

				& > button {
					background-color: transparent;
					padding: 8px;
					outline: 0;
					border: 0;
					color: $light;
					transition: 0.3s;
					cursor: pointer;
					transform: scale(1.5, 1.5);
					margin-right: 15px;

					&:hover {
						transform: scale(2, 2);
					}
				}
			}

			.content {
				padding: 26px;
				background-color: $light;
				overflow-y: auto;
				flex: 1;
			}
		}

		&.entering {
			opacity: 1;

			.frame {
				transform: translateX(0);
			}
		}

		&.entered {
			opacity: 1;
			pointer-events: inherit;


			.frame {
				transform: translateX(0);
			}
		}

		&.exiting {
			opacity: 0;

			.frame {
				transform: translateX(90vw);
			}
		}

		&.exited {
			opacity: 0;

			.frame {
				opacity: 0;
				transform: translateX(90vw);
			}
		}
	}

	@media screen and (max-width: 1100px) {
		.panel {
			max-width: 800px;
		}
	}

	@media screen and (max-width: 900px) {
		& > h2 {
			font-size: 40px;
		}

		.panel {
			flex-direction: column;
			max-width: 600px;

			.case .title {
				text-align: center;
			}
		}
	}
}
