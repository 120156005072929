@import "assets/styles/colors.scss";


@function stroke($stroke, $color) {
	$shadow: ();
	$from: $stroke*-1;
	@for $i from $from through $stroke {
		@for $j from $from through $stroke {
			$shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
		}
	}
	@return $shadow;
}

.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: relative;
	background-color: $light;

	svg {
		opacity: 1;
		transition: 0.3s;
	}

	.holder {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.main {
			position: relative;
			font-size: 50px;
			max-width: 800px;
			text-align: center;
			color: $accent;
			padding: 7px 14px;
			text-shadow: stroke(8, $light);
			animation: data 3s;

			b {
				font-size: 190%;
			}
		}

		.extra {
			margin-top: 50px;
			font-size: 30px;
			max-width: 800px;
			text-align: center;
			color: $accent;
			padding: 7px 14px;
			text-shadow: stroke(8, $light);
			animation: data 3s;

			b {
				font-size: 190%;
			}
		}
	}

	&.started {
		opacity: 1;
	}

	@media screen and (max-width: 900px) {
		.holder {
			.main {
				font-size: 34px;
				text-shadow: stroke(4, $light);

				b {
					font-size: 130%;
				}
			}

			.extra {
				margin-top: 30px;
				font-size: 20px;
				text-shadow: stroke(4, $light);

				b {
					font-size: 130%;
				}
			}
		}
	}
}

@keyframes data {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
