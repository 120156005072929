@import "assets/styles/colors.scss";

.navigation {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 200px;
	z-index: 10;
	display: flex;
	align-items: center;

	.holder {
		border: 1px solid darken($light-alternative, 5);
		border-left: 0;
		box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 10px;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s;

		& > div {
			color: $dark;
			background-color: $light;
			border-left: 5px solid transparent;
			font-size: 17px;
			transition: 0.3s;
			cursor: pointer;
			padding: 9px 28px 9px 16px;

			& > span {
				transition: 0.3s;
				display: block;
			}

			&:not(:last-child) {
				border-bottom: 1px solid darken($light-alternative, 5);
			}

			&:hover {
				background-color: darken($light-alternative, 5);

				& > span {
					text-shadow: 0 0 1px $dark;
				}
			}

			&.active {
				border-left: 5px solid $accent;
				color: black;
			}
		}
	}

	&.active .holder{
		opacity: 1;
		pointer-events: inherit;
	}

	@media screen and (max-width: 1000px) {
		.holder {
			& > div {
				padding: 8px 18px 8px 14px;
			}
		}
	}

	@media screen and (max-width: 800px) {
		display: none;
	}
}
