@import "assets/styles/colors.scss";

* {
	margin: 0;
	padding: 0;
	user-select: none;
}

@font-face {
	font-family: "work-sans";
	src: url("../fonts/WorkSans-Light.ttf") format("truetype");
}

html, body {
	font-family: "work-sans";
	height: 100%;
	background-color: $light;
	color: lighten($dark, 20);

	button {
		font-family: "work-sans";
		font-weight: normal;
	}
}

#root {
	height: 100%;
	display: flex;
}

::-webkit-scrollbar {
	width: 15px;
}

::-webkit-scrollbar-track {
	background: $light;
}

::-webkit-scrollbar-thumb {
	background: $accent;
	border: 4px solid $light;
	border-radius: 8px;

	&:hover {
		background: lighten($accent, 5);
	}
}
